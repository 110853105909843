export const homeObjFive = {
  id: "projects",
  lightBg: true,
  primary: false,
  imgStart: "start",
  lightTopLine: false,
  topLine: "",
  lightText: false,
  lightTextDesc: false,
  headline: "Projects",
  description: "Web CRUD app for scheduling a neighbourhood tool rental built with Postgres, Express, React, Node.",
  description2: "Website designed and built using WordPress CMS with additional functional elements added using JS and jQuery.",
  description3: "E-commerce store built on the Shopify platform with custom features injected using Liquid templating language.",
  description4: "Custom web storefront for a boutique chocolatier, built on WordPress CMS with Elementor and jQuery.",
  buttonLabel: "",
  img: require("../../images/ToolSwap.jpeg"),
  img2: require("../../images/OAEA.png"),
  img3: require("../../images/StudioPothos.jpeg"),
  img4: require("../../images/MilonBackt.jpeg"),
  start: "",
  alt: "Image",
  viewButton: false,
};